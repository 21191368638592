import defaultTheme from 'tailwindcss/defaultTheme'

export default {
  theme: {
    extend: {
      fontFamily: {
        sans: [ 'Montserrat', defaultTheme.fontFamily.sans ]
      },

      colors: {
        primary: '#0f1923',
        secondary: '#1d2730',
        
        card: '#1a242d',
        input: '#0f1923',
        separator: '#323b45',

        bzRed: '#115e15',
        bzDarkRed: '#115e15a3',

        bzMenuTextLight: '#bcbfc7',
        bzMenuText: '#8c9099',
        bzMenuTextDark: '#464d5e',
      }
    }
  }
}