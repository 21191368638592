<script setup>
import { useMenuStore } from '~/stores/menu';

const menuStore = useMenuStore()
</script>

<template>
    <!-- Menu Mobile -->
    <div class="flex flex-row justify-between h-16 fixed gap-x-4 bottom-0 left-0 right-0 bg-secondary text-white border-t border-separator xl:hidden z-30">
        <div id="left" class="flex flex-row justify-around flex-grow">
            <MenuMobileItem @click="menuStore.toggle()">
                <MenuMobileIcon>
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'top-rotate': menuStore.menuOpened }">
                    </div>
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'move-x': menuStore.menuOpened }">
                    </div>
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'bottom-rotate': menuStore.menuOpened }">
                    </div>
                </MenuMobileIcon>

                <MenuMobileText>{{ !menuStore.menuOpened ? 'Menu' : 'Fechar' }}</MenuMobileText>
            </MenuMobileItem>

            <MenuMobileItem>
                <NuxtLink to="/transactions" class="text-center">
                    <MenuMobileIcon class="group-hover:text-bzMenuTextDark mb-2">
                        <Icon name="guidance:bank" size="22" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-bzMenuTextDark">
                        Transações
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>
        </div>
        <div id="right" class="flex flex-row justify-around flex-grow">
            <MenuMobileItem>
                <NuxtLink to="/users" class="text-center">
                    <MenuMobileIcon class="group-hover:text-bzMenuTextDark mb-2">
                        <Icon name="clarity:users-line" size="25" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-bzMenuTextDark">
                        Indicações
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>

            <MenuMobileItem>
                <NuxtLink to="/withdraw" class="text-center">
                    <MenuMobileIcon class="group-hover:text-bzMenuTextDark mb-2">
                        <Icon name="streamline:money-atm-card-2-deposit-money-payment-finance-atm-withdraw" size="22" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-bzMenuTextDark">
                        Saque
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>
        </div>
    </div>
</template>

<style scoped>

.move-x {
    transform: translateX( 30px );
    opacity: 0;
}

.top-rotate {
    transform-origin: center;
    transform: rotateZ( 45deg ) translateY( 6px ) translateX( 1.5px );
}

.bottom-rotate {
    transform-origin: center;
    transform: rotateZ( -45deg ) translateY( -7px ) translateX( 2px );
}

</style>