<script setup>
import { useMenuStore } from '~/stores/menu'
import { useUserStore } from '~~/stores/user';
const userStore = useUserStore()

const menuStore = useMenuStore()
</script>

<template>
    <div 
        
        :class="[
            (
                menuStore.menuOpened
                ? 'xl:w-1/6'
                : '-translate-x-full xl:translate-x-0 xl:w-[15%]'
            ),
            (
                menuStore.targeOpened
                ? 'xl:top-[4.0rem]'
                : 'xl:top-16'
            )

        ]" class="bg-primary xl:bottom-0 xl:flex w-screen fixed z-50 top-0 bottom-16 flex flex-col transition-all duration-500 xl:duration-0 easy-in-out overflow-y-scroll xl:border-r border-separator [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        <!-- -->
        <div class="flex flex-row items-center justify-between h-16 p-4 xl:hidden">
            <NavbarLogo />
            <div @click="menuStore.toggle()" class="cursor-pointer text-bzMenuTextDark">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-10 h-10">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <div class="flex flex-col">
            <MenuItem class="gap-y-2">
                <template #body>
                    <div class="w-full h-auto pt-4 mb-4">
                        <MenuDropdownItem to="/dashboard">
                            <Icon name="codicon:dashboard" size="32" />
                            Dashboard
                        </MenuDropdownItem>
                    </div>
                </template>
            </MenuItem>
            <div class="flex flex-col text-white">
         

                <MenuItem class="gap-y-2">
                    <template #body>
                        <div class="w-full h-auto pt-4">
                            <MenuDropdownItem to="/transactions">
                                <Icon name="guidance:bank" size="32" />
                                Transações
                            </MenuDropdownItem>

                            <MenuDropdownItem to="/users" class="mt-6">
                                <Icon name="clarity:users-line" size="32" />
                                Indicações
                            </MenuDropdownItem>
                            <MenuDropdownItem to="/affiliates" class="mt-6">
                                <Icon name="mdi:handshake-outline" size="32" />
                                Afiliados
                            </MenuDropdownItem>

                            <MenuDropdownItem to="/withdraw" class="mt-6">
                                <Icon name="streamline:money-atm-card-2-deposit-money-payment-finance-atm-withdraw" size="32" />
                                Saque
                            </MenuDropdownItem>

                            <MenuDropdownItem to="/pixel" class="mt-6">
                                <Icon name="material-symbols:robot-2-outline" size="32" />
                                Pixel
                            </MenuDropdownItem>

                            <MenuDropdownItem to="/postback" class="mt-6">
                                <Icon name="streamline:mail-send-email-send-email-paper-airplane" size="26" />
                                Postabck
                            </MenuDropdownItem>
                        </div>
                    </template>
                </MenuItem>
            </div>
        </div>
    </div>
</template>
