<template>
    <div class="sticky z-40 top-0">
        <div class="flex flex-row h-16 px-4 text-white border-b border-separator xl:justify-between bg-primary">   
          
            <div class="flex flex-row items-center justify-between w-full  2xl:-ml-60">
                <NavbarLogo />

                <div v-if="userStore.isLoggedIn" class="flex gap-2">
                    <div class="border border-gray-700 rounded-lg text-base text-white p-2 whitespace-nowrap">
                        <span class="">
                           {{ money(userStore.balance /100) }}
                        </span>
                    </div>

                    <Menu as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton
                            class="flex border border-gray-700 p-2 mx-auto  justify-center rounded-lg bg-black bg-opacity-20 text-sm font-medium text-white
                            hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-200 focus-visible:ring-opacity-75"
                            >
                            <Icon name="ic:baseline-person" class="my-auto " size="22px" />                            
                            </MenuButton>
                        </div>

                        <transition
                            enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0"
                            enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0"
                        >
                            <MenuItems
                            class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                            <div class="px-1 py-1">

                                <MenuItem v-slot="{ active, close }">
                                <nuxt-link to="/">

                                    <MenuDropdownItem @click="close" :class="[
                                    active ? 'bg-primary text-white' : 'text-gray-900',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                    ]">
                                        Conta
                                        
                                    </MenuDropdownItem>
                                </nuxt-link>
                                </MenuItem>
                                
                            </div>

                            <div class="px-1 py-1">
                                <MenuItem v-slot="{ active }">
                                <button
                                    @click="userStore.logout()"
                                    :class="[
                                    active ? 'bg-primary text-white' : 'text-gray-900',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                    ]"
                                >
                                
                                    Sair
                                </button>
                                </MenuItem>
                            </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
    import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
    import { useUserStore } from '~/stores/user'
    const userStore = useUserStore()
</script>